<template>
  <div class="search main_top">
    <div class="search_container hidden-sm-and-up">
        <div class="search_head flex_sb">
            <div class="iconfont icon-back-line-free icon_back" @click="mobileFormeVisble=false"></div>
            <span>Search</span>
            <span></span>
        </div>
        <div class="mobile_search">
          <div class="search_box " > 
            <el-input type="text" placeholder="Search games or goods" v-model="search" @keyup.enter.native="onSearch" prefix-icon="el-icon-search">
            </el-input>
          </div>
        </div>
        <div class="search_result " >
            <div class="result_empty flex_center" v-if="!allGameList.length">
                <div >
                    <div class="empty_img"><img src="../../assets/image/s_empty.png" alt=""></div>
                   <div class="empty_text">Cannot find the item </div>
                </div>
                
            </div>
            <div class="result_has" v-else>
                <!-- <div class="has_title">Game</div> -->
                <div class="has_content grid" >
                    <div class="grid_md_4 grid_sm_6 thumb_group " v-for="(i,index) in allGameList" :key="index" @click="toPage('/details',i.id,i.allName)" style="background:#eff2f5;">
                        <div class="group_centent flex">
                            <div class="group_img"><img :src="i.upImage" alt=""></div>
                            <div class="group_info">
                                <div class="pic_name">{{ i.tranName }}</div>
                                <div class="pic_cell flex_start_center">
                                    <div class="pic_num"><span>5.0</span></div>
                                    <div class="pic_start flex_center">
                                        <el-rate v-model="rateNum" disabled></el-rate>
                                    </div>
                                    <div class="pic_review">1207 {{$t('key16')}}</div>
                                </div>
                                <div class="pic_sold">200k+ {{$t('key17')}}</div>
                            </div>
                            <!-- <div class="cell_off">-21%</div> -->
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="search_pc hidden-xs-only">
        <div class="search_title">{{ $t('key112') }}: {{ searchName }}</div>
        <div class="search_result">
            <div class="result_empty flex_center" v-if="!allGameList.length">
                <div>
                    <div class="empty_img"><img src="../../assets/image/s_empty.png" alt=""></div>
                    <div class="empty_text">{{ $t('key111') }}</div>
                </div>
            </div>
            <div class="result_has" v-else>
                <div class="has_title">{{ $t('key2') }}</div>
                <div class="has_content grid">
                    <div class="grid_md_4 grid_sm_6 thumb_group hidden-xs-only" v-for="(i,index) in allGameList" :key="index" @click="toPage('/details',i.id,i.allName)">
                        <div class="group_centent flex">
                            <div class="group_img"><img :src="i.upImage" alt=""></div>
                            <div class="group_info">
                                <div class="pic_name">{{ i.tranName }}</div>
                                <div class="pic_cell flex_start_center">
                                    <div class="pic_num"><span>5.0</span></div>
                                    <div class="pic_start flex_center">
                                        <el-rate v-model="rateNum" disabled></el-rate>
                                    </div>
                                    <div class="pic_review">1207 {{$t('key16')}}</div>
                                </div>
                                <div class="pic_sold">200k+ {{$t('key17')}}</div>
                            </div>
                            <!-- <div class="cell_off">-21%</div> -->
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    </div>
   
  </div>
</template>

<script>
import {processString} from '../../utils/tool'
export default {
    data(){
        return{
            search:'',
            rateNum:5,
            searchName:'',
            allGameList:[],
            pageIndex:1,
            pageSize:20,
            isLastPage:false
        }
    },
    created(){
        let search = this.$route.params.search
       
        this.searchName = search
      
        this.getSearch()
    },
    methods:{
        getSearch(){
            let gameAlias =this.searchName
            let pageSize = this.pageSize
            let pageIndex = this.pageIndex
            let lang = this.$store.state.lang
            console.log('gameAlias',gameAlias)
            this.$axiosApi.getAllGameList({gameAlias,pageIndex,pageSize}).then(res=>{
                if(res.code == 200){
                    let allGameList = res.data.list
                    this.isLastPage = res.data.isLastPage
                   
                     allGameList.map(v=>{
                        if(!/^\s*$/.test(v.allName)){
                            v.allName = JSON.parse(v.allName)
                            v.allName.map(c=>{
                            if(c.language ==lang.dictValue ){
                                v.tranName = c.name
                            }  
                         })
                        } 
                        this.allGameList.push(v)
                        return v
                    })
                   
                }
                
            })
        },
        toPage(path,id,allName){
            let lang = localStorage.getItem('lang')
            let tranName = allName
            let name = ''
            let gameName = ''
            if(!/^\s*$/.test(allName)){
                if(typeof(allName) === 'string'){
                    tranName = JSON.parse(allName)
                }            
                tranName.map(c=>{
                if(c.language =='1' ){
                    name = c.name
                }  
                })
            }
           
            gameName = processString(name)
         
 
            this.$router.push(path+'/'+lang+'/'+id+'/'+gameName)
        },
        handleScroll() {
            const scrollY = window.scrollY;
            const windowHeight = window.innerHeight;
            const documentHeight = document.documentElement.scrollHeight;
            const bottomOfPage = windowHeight + scrollY >= documentHeight;   
            if (bottomOfPage && !this.isLastPage) {
                this.pageIndex = this.pageIndex+1
                this.getSearch()
            }         
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)  
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
}
</script>

<style lang="scss" scoped>

    
.search_container{
    position: fixed;
    left:0;
    right:0;
    top:48px;
    background: #fff;
    padding:0 16px;
    .search_head{
        width: 100%;
        font-size: 16px;
        color: #1f2128;
        font-weight: 600;
        line-height: 44px;
        height: 44px;
        .icon_back{
            cursor: pointer;
        }
    }
    .mobile_search{
    width: 100%;
    .search_box{
        height: 100%;
        width: 100%;
        position: relative;
        padding:0 0 16px;
        /deep/.el-input__inner{
            height: 48px;
            line-height: 48px;
            width:100%;
            // background:$iconColor;
            border:1px solid #eff2f5;
            padding:0 28px;
            &::placeholder{
                color:$iconColor;
                text-align: left;
            }
            }
            /deep/.el-input__prefix{
            
                line-height: 48px;
            }
            .el-input__icon{
            line-height: 48px;
            // color:#fff;
            &::before{
                font-size: 20px;
                }
            }
        .search_icon{
            position: absolute;
            left:32%;
            z-index: 9;
            top:12px;
            color:$iconColor;
        }
    }
}
   
}
.result_empty{
    width: 100%;
    height: 80vh;
    .empty_img{
        width:160px;
    }
    .empty_text{
        color:#1f212840;
        margin-top:30px;
        font-size: 15px;
    }
}
.has_content{
        grid-gap:8px;
        padding-bottom:16px;
        .thumb_group{
            background:#fff;
            border-radius: 4px;
            padding:14px 16px;
            transition: all .1s linear;
            cursor: pointer;
            &:hover{
                transform: translateY(-2px);
                box-shadow:  0px 8px 10px -5px rgba(0, 0, 0, .08), 0px 16px 24px 2px rgba(0, 0, 0, .04), 0px 6px 30px 5px rgba(0, 0, 0, .05);
                .group_img{
                    transform:scale(1.1);
                }
            }
            .group_centent{
                position: relative;
                .group_img{
                    height: 64px;
                    width: 64px;
                    border-radius: 4px;
                    overflow: hidden;
                    margin-right:12px;
                    transition: all .1s linear;
                }
                .group_info{
                    flex:1;
                    .pic_name{
                        color:#0f0f0f;
                        font-size: 15px;
                        line-height: 24px;
                        text-align: left;
                    }
                    .pic_cell{
                        width: 100%;
                        .pic_num{
                            >span{
                                min-width: 21px;
                                padding:0 4px;
                                background: #fc0;
                                color:#000000b2;
                                border-radius: 2px;
                                font-size: 12px;
                                text-align: center;
                                line-height: 16px;
                            }
                            
                            margin-right:4px;
                        }
                        .pic_start{
                            /deep/.el-rate__icon{
                                margin-right:1px;
                                font-size: 16px;
                            }
                        }
                        .pic_review{
                            font-size: 14px;
                            color:#5e626b;
                        }
                    }
                    .pic_sold{
                        text-align: left;
                        font-size: 12px;
                        color:#77808c;
                    }
                }
                .cell_off{
                    font-size: 14px;
                    color:#fff;
                    background:#ff8000;
                    border-radius: 4px;
                    position: absolute;
                    padding:0 6px;
                    top:-10px;
                    right:-12px;
                    line-height: 18px;
                }
            }
        }
      }
.search_pc{
    width: 100%;
   
   .search_title{
    padding:0 24px;
    background: #fff;
    border-radius: 4px;
    height: 56px;
    margin:14px 0 8px;
    color: rgba(31, 33, 40, .9);
    font-size: 20px;
    text-align: left;
    line-height: 56px;
    font-weight: 600;
   }
   .search_result{
    margin-top:8px;
    // background: #fff;
    .has_title{
        padding: 12px 16px;
        font-weight: 600;
        font-size: 15px;
        line-height: 23px;
        border-bottom: .5px solid #ebecf0;
        background: #fff;
        color:#1f2128;
        text-align: left;
    
    }
    
   }
    
}
 

</style>